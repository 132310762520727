import MarketplaceBlockImage from "../MarketplaceBlockImage";
import createOwnPluginImage from 'public/assets/marketplace_create-your-own-plugin.svg';

const MarketplaceBlockSubmitPlugin = () => {
  return (
    <MarketplaceBlockImage 
      title="Create your own plugin"
      description="Check out the available plugin resources that will help you to develop your plugin or provider and get it listed on the marketplace."
      buttonText="Get started"
      buttonLink="https://strapi.io/plugin-resources"
      image={createOwnPluginImage}
      withMarginTop
    />
  );
}

export default MarketplaceBlockSubmitPlugin;
