import Button from 'src/components/shared/Button';
import css from './styles.module.scss';
import Img from 'next/image';
import classnames from 'classnames/bind';

const cx = classnames.bind(css);

const MarketplaceBlockImage = ({ title, description, image, buttonText, buttonLink, withMarginTop = false }) => {
  return (
    <div className={cx(
      css.marketplaceBlockImage,
      withMarginTop && css.marketplaceBlockImage__marginTop
    )}>
      <div className={css.marketplaceBlockImage__leftSide}>
        <h3 className={css.marketplaceBlockImage__leftSide__title}>{title}</h3>
        <p className={css.marketplaceBlockImage__leftSide__description}>{description}</p>
        <Button
          id="plugins-resources-button"
          theme="marketplaceBlue"
          link={{
            href: buttonLink,
            target: '_blank'
          }}
          className={css.marketplaceBlockImage__leftSide__button}
        >
          {buttonText}
        </Button>
      </div>
      <div className={css.marketplaceBlockImage__rightSide}>
        <Img 
          src={image}
          layout="fill"
        />
      </div>
    </div>
  );
};

export default MarketplaceBlockImage;
